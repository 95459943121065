import {
	APP_INITIALIZER,
	CUSTOM_ELEMENTS_SCHEMA,
	Injector,
	LOCALE_ID,
	NgModule
} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {
	HTTP_INTERCEPTORS,
	HttpClient,
	HttpClientModule
} from '@angular/common/http';
import {SocketIoConfig, SocketIoModule} from 'ngx-socket-io';
import {SocketService} from './services/socket.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginPage} from './pages/auth/login/login.page';
import {AuthInterceptor} from './interceptors/onRequest';

import {
	TranslateLoader,
	TranslateModule,
	TranslateService
} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {LOCATION_INITIALIZED, registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {NgSelectModule} from '@ng-select/ng-select';

import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {clearState} from './global-clear-state.reducer';
import {AuthStateModule} from './store/auth-state/+state/auth-state.module';

import {environment} from '../environments/environment';

import {ManagerStateModule} from './store/manager/+state/manager-state.module';
import {ManagerComponentsModule} from './components/manager-components/manager-components.module';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {ManagerPage} from './pages/manager/manger.page';
import {MaterialModule} from './material-module/material-module';
import {ToastrModule} from 'ngx-toastr';
import {FaqPage} from './pages/faq/faq.page';
import {StockManagerComponentsModule} from './components/stock-manager-components/stock-manager.module';
import {StockManagerPage} from './pages/stock-manager/stock-manager.page';
import {AdminConsolePage} from './pages/admin console/admin-console.page';
import {AdminConsoleOptionsComponent} from './components/admin-console-options/admin-console-options.component';
import {PinCodePage} from './pages/pin-code/pin-code.page';
import {ArticleModalModule} from './components/article-modal/article-modal.module';
import {OpeningHoursPage} from './pages/opening-hours/opening-hours.page';
import {ArticleWithGroupsModalModule} from './components/article-with-groups-modal/article-with-groups-modal.module';
import {UpdateExternalDeliveryDialogComponent} from './pages/stock-manager/update-external-delivery-dialog/update-external-delivery-dialog.component';

const config: SocketIoConfig = {
	url: environment.apiUrl,
	options: {
		path: '/v1/socketio/socket.io/'
	}
};

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export function appInitializerFactory(
	translate: TranslateService,
	injector: Injector
) {
	return () =>
		new Promise<any>((resolve: any) => {
			const locationInitialized = injector.get(
				LOCATION_INITIALIZED,
				Promise.resolve(null)
			);
			locationInitialized.then(() => {
				translate.use(translate.defaultLang).subscribe({
					next: () => {},
					error: () => {},
					complete: () => resolve(null)
				});
			});
		});
}
export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [
		AppComponent,
		LoginPage,
		AdminConsolePage,
		ManagerPage,
		FaqPage,
		StockManagerPage,
		AdminConsoleOptionsComponent,
		PinCodePage,
		OpeningHoursPage,
		UpdateExternalDeliveryDialogComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		MaterialModule,
		AppRoutingModule,
		SocketIoModule.forRoot(config),
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule,
		AuthStateModule,
		ManagerStateModule,
		ManagerComponentsModule,
		StockManagerComponentsModule,
		NgSelectModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			},
			defaultLanguage: 'en'
		}),
		StoreModule.forRoot(
			{},
			{
				metaReducers: [clearState],
				runtimeChecks: {
					strictActionImmutability: true,
					strictStateImmutability: true
				}
			}
		),
		EffectsModule.forRoot([]),
		StoreDevtoolsModule.instrument({connectInZone: true}),
		ToastrModule.forRoot({
			positionClass: 'toast-bottom-right',
			preventDuplicates: true
		}),
		ArticleModalModule,
		ArticleWithGroupsModalModule
	],
	providers: [
		SocketService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFactory,
			deps: [TranslateService, Injector],
			multi: true
		},
		{provide: LOCALE_ID, useValue: 'en-EN'},
		provideAnimationsAsync()
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
