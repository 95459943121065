import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';

import {AuthFacade} from 'src/app/store/auth-state/+state/auth.facade';
import {combineLatest, filter, map, Subject, takeUntil, timer} from 'rxjs';

import {ManagerFacade} from 'src/app/store/manager/+state/manager.facade';
import {OrderStatus} from 'src/app/models/OrderStatus';
// import { OrderType } from 'src/app/models/OrderType';
// import * as moment from 'moment';
// import { Socket } from 'ngx-socket-io';
// import { SocketRole } from 'src/app/models/SocketRoles';
import Order from 'src/app/models/Order';
import {OrderService, STATUS_CHANGE} from 'src/app/services/order.service';
// import { Table } from 'src/app/models/Table';
import {NavigationService} from 'src/app/services/navigation.service';
import {UpdateExternalDeliveryDialogComponent} from './update-external-delivery-dialog/update-external-delivery-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
@Component({
	selector: 'app-stock-manager',
	templateUrl: './stock-manager.page.html',
	styleUrls: ['./stock-manager.page.scss']
})
export class StockManagerPage implements OnDestroy, OnInit {
	private readonly onDestroy$ = new Subject<void>();
	// private readonly stopListeningSocketForTable$ = new Subject<void>();
	public isScrolled = false;
	public disabledWolt = false;
	public disableUber = false;
	public disableJet = false;
	@HostListener('window:scroll', [])
	onWindowScroll() {
		const yOffset = window.pageYOffset;
		const scrollTopButtonVisibleOffset = 200;
		this.isScrolled = yOffset > scrollTopButtonVisibleOffset;
	}
	public venue$ = this.managerFacade.venue$;
	public orders$ = combineLatest(
		this.managerFacade.orders$,
		this.managerFacade.orderStatusFiler$
	).pipe(
		takeUntil(this.onDestroy$),
		map(([ordersValue, status]) => {
			let orders = JSON.parse(JSON.stringify(ordersValue));
			const statusOrder = [
				null,
				OrderStatus.AWAITING_CONFIRMATION,
				OrderStatus.IN_PREPARATION,
				OrderStatus.READY,
				OrderStatus.DONE
			];
			if (orders.length > 0)
				orders.sort((a, b) => {
					return (
						statusOrder.indexOf(a?.status ?? null) -
						statusOrder.indexOf(b?.status ?? null)
					);
				});
			if (status != OrderStatus.DONE) {
				orders = orders.filter(it => it.status != OrderStatus.DONE);
			}
			if (!status) {
				return orders;
			}
			orders = orders.filter(it => it.status == status);
			return orders;
		})
	);
	public statusChangeENUM = STATUS_CHANGE;
	constructor(
		private authFacade: AuthFacade,
		private managerFacade: ManagerFacade,
		// private socket: Socket,
		private orderService: OrderService,
		private navService: NavigationService,
		public dialog: MatDialog,
		private toaster: ToastrService
	) {}

	ngOnInit(): void {
		this.authFacade.userData$
			.pipe(
				filter(userValue => !!userValue),
				map(userData => {
					return userData?.venues[0] ?? null;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(venueId => {
				if (venueId) {
					this.loadDataByUser(venueId);
				}
			});
		this.managerFacade.successSync$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(v => {
				if (v) {
					this.toaster.success('Sync started...', 'Success', {
						timeOut: 5000
					});
					this.managerFacade.killSuccess();
				}
			});
		// this.managerFacade.tables$
		// 	.pipe(
		// 		filter(device => device.length > 0),
		// 		takeUntil(this.stopListeningSocketForTable$)
		// 	)
		// 	.subscribe(tables => {
		// 		if (tables.length > 0) {
		// 			this.listenSocket(tables);
		// 		}
		// 	});
	}
	private loadDataByUser(venueId: string) {
		this.managerFacade.loadVenueData({venueId});
		this.loadOrdersWithDefaultConfig(venueId);
	}
	ngOnDestroy() {
		this.onDestroy$.next();
	}
	private loadOrdersWithDefaultConfig(venueId: string) {
		// this.managerFacade.loadOrders({
		// 	venue: venueId,
		// 	status: [
		// 		OrderStatus.AWAITING_CONFIRMATION,
		// 		OrderStatus.IN_PREPARATION,
		// 		OrderStatus.READY,
		// 		OrderStatus.DONE
		// 	],
		// 	orderTypes: [
		// 		OrderType.TERMINAL,
		// 		OrderType.STANDARD,
		// 		OrderType.VIRTUAL,
		// 		OrderType.PREORDER
		// 	],
		// 	from: moment().subtract(1, 'days').toString(),
		// 	to: moment().add(2, 'days').toString(),
		// 	promoCode: true
		// });
		// this.listenSocketOrders(venueId);
	}
	// private listenSocketOrders(venueId: string) {
	// 	this.socket.on('connect', () => {
	// 		this.authenticateSocket({
	// 			roles: [SocketRole.COUNTER_DISPLAY, SocketRole.COUNTER_DISPLAY_MAIN],
	// 			venueIds: [venueId]
	// 		});
	// 	});

	// 	this.socket.on('venue-' + venueId + '-orders', (data: any) => {
	// 		if (data?.order && data?.type == 'update') {
	// 			this.updateState(data.order);
	// 		}
	// 	});
	// }
	// private updateState(order: Order) {
	// 	this.managerFacade.updateOrder(order);
	// }
	// private authenticateSocket(data: {
	// 	roles: SocketRole[];
	// 	venueIds: string[];
	// }): void {
	// 	this.socket.emit('authenticate', data.roles, data.venueIds, (ack: any) => {
	// 		console.log('Socket authenticated for ', ack, data);
	// 	});
	// }
	// private listenSocket(tables: Table[]) {
	// 	console.log('load Socket');
	// 	tables.forEach(it => {
	// 		this.socket.on(
	// 			`terminalStatus_${it.venue}_${it.number.replace(' ', '_')}`,
	// 			(data: any) => {
	// 				console.log('EMIT STATUS', data);
	// 				this.managerFacade.updateTable({ ...it, status: data ?? 'Failed' });
	// 			}
	// 		);
	// 	});
	// }
	updateStatus(data: {order: Order; status: STATUS_CHANGE}) {
		if (!data || !data.order || !data.status) {
			return;
		}
		switch (data.status) {
			case STATUS_CHANGE.PREV:
				this.managerFacade.updateOrderStatus(
					data.order,
					this.orderService.getPreviousOrderStatus(data.order.status)
				);
				break;
			case STATUS_CHANGE.NEXT:
				this.managerFacade.updateOrderStatus(
					data.order,
					this.orderService.getNextOrderStatus(data.order.status)
				);
				break;
			default:
				break;
		}
	}
	stockManager() {
		this.navService.stockManager();
	}
	listenGoBack() {
		console.log('GO BACK');

		this.venue$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(v => {
				if (
					v?.externalOrderProviders?.jet?.enabled ||
					v?.externalOrderProviders?.wolt?.enabled ||
					v?.externalOrderProviders?.uberEats?.enabled
				) {
					const dialogRef = this.dialog.open(
						UpdateExternalDeliveryDialogComponent,
						{
							data: {
								...v
							}
						}
					);

					dialogRef.afterClosed().subscribe((result: {}) => {
						this.navService.managerPage();
					});
				} else {
					this.navService.managerPage();
				}
			})
			.unsubscribe();
		this.managerFacade.setStockManagerFilters({
			category: '',
			status: '',
			type: '',
			search: ''
		});
	}

	scrollToTop() {
		window.scrollTo({top: 0, behavior: 'smooth'});
	}
	syncUber(venueId: string) {
		this.disableUber = true;
		timer(5000)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(v => {
				this.disableUber = false;
			});
		this.managerFacade.syncUber(venueId);
		this.managerFacade.errorSyncUber$
			.pipe(takeUntil(timer(5000) || this.onDestroy$))
			.subscribe(error => {
				if (error) {
					this.toaster.error(error, 'Uber Sync Error', {
						timeOut: 5000
					});
				}
			});
	}
	syncJet(venueId: string) {
		this.disableJet = true;
		timer(5000).subscribe(v => {
			this.disableJet = false;
		});
		this.managerFacade.syncJet(venueId);
		this.managerFacade.errorSyncJet$
			.pipe(takeUntil(timer(5000) || this.onDestroy$))
			.subscribe(error => {
				if (error) {
					this.toaster.error(error, 'Jet Sync Error', {
						timeOut: 5000
					});
				}
			});
	}
	syncWolt(venueId: string) {
		this.disabledWolt = true;
		timer(5000).subscribe(v => {
			this.disabledWolt = false;
		});
		this.managerFacade.syncWolt(venueId);
		this.managerFacade.errorSyncWolt$
			.pipe(takeUntil(timer(5000) || this.onDestroy$))
			.subscribe(error => {
				if (error) {
					this.toaster.error(error, 'Wolt Sync Error', {
						timeOut: 5000
					});
				}
			});
	}
}
