<div class="wrapper">
	<button mat-stroked-button class="back-button" (click)="getBack()">
		{{"common.get_back" | translate}}
	</button>

	<div class="stars">
		<p class="info">{{'pin_code.title' | translate}}</p>
		<div class="field">
			@for (item of lengthOfCode; track $index) {
			<p class="star" [class.active]="inputValue.getValue().length > $index">
				*
			</p>
			}
		</div>
	</div>

	<div class="numbers">
		<div class="row">
			<button mat-stroked-button (click)="onNumber($event)">1</button>
			<button mat-stroked-button (click)="onNumber($event)">2</button>
			<button mat-stroked-button (click)="onNumber($event)">3</button>
		</div>
		<div class="row">
			<button mat-stroked-button (click)="onNumber($event)">4</button>
			<button mat-stroked-button (click)="onNumber($event)">5</button>
			<button mat-stroked-button (click)="onNumber($event)">6</button>
		</div>
		<div class="row">
			<button mat-stroked-button (click)="onNumber($event)">7</button>
			<button mat-stroked-button (click)="onNumber($event)">8</button>
			<button mat-stroked-button (click)="onNumber($event)">9</button>
		</div>
		<div class="row">
			<button mat-stroked-button (click)="onNumber($event)">0</button>
		</div>
	</div>
</div>
