import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, combineLatest, map, takeUntil, tap} from 'rxjs';
import {ManagerFacade} from 'src/app/store/manager/+state/manager.facade';
import {numberD, numberToCurrency} from 'src/app/services/order.service';
import {trigger, state, style, animate, transition} from '@angular/animations';
import {CategoryToArticleService} from 'src/app/services/categoryToArticles.service';
import {MatDialog} from '@angular/material/dialog';
// import { ArticleModalComponent } from '../../article-modal/article-modal.component';
import {OptionGroup} from 'src/app/models/OptionGroup';
import ArticleCategory from 'src/app/models/ArticleCategory';
import Article from 'src/app/models/Article';
import {StockManagementType} from 'src/app/models/StockManagerType';
import {ArticleWithGroupsModalComponent} from '../../article-with-groups-modal/article-with-groups-modal.component';

@Component({
	selector: 'app-stock-manager-draw-articles-new',
	templateUrl: './stock-manager-draw-articles-new.component.html',
	styleUrls: ['./stock-manager-draw-articles-new.component.scss'],
	animations: [
		trigger('drawCard', [
			state(
				'start',
				style({
					opacity: 0,
					transform: 'scale(0)'
				})
			),
			state(
				'end',
				style({
					opacity: 1,
					transform: 'scale(1)'
				})
			),
			transition('start => end', [animate('500ms ease-out')])
		])
	]
})
export class StockManagerDrawArticlesNewComponent implements OnInit, OnDestroy {
	public numberToCurrency = numberToCurrency;
	public numberD = numberD;
	animationState = 'start';
	public venueId: string;
	private onDestroy$ = new Subject<void>();
	articles$: Observable<{
		article: {
			parentName: string;
			connectedArticles: {de: string}[];
			parentDescription: string;
			article: Article;
			type: StockManagementType;
		}[];
		option: {
			parentName: string;
			connectedArticles: {de: string}[];
			parentDescription: string;
			article: Article;
			type: StockManagementType;
		}[];
	}> = combineLatest([
		this.managerFacade.categories$,
		this.managerFacade.filtersStockManager$,
		this.managerFacade.venue$,
		this.managerFacade.optionGroups$
	]).pipe(
		tap(() => {
			this.animationState = 'start';
		}),
		map(([categoriesValue, filters, venue, optionGroupsValue]) => {
			//TODO: logic is added but need refactor
			const copyOfOptionGroupsValue = JSON.parse(
				JSON.stringify(optionGroupsValue)
			) as OptionGroup[];
			let categories = JSON.parse(
				JSON.stringify(categoriesValue)
			) as ArticleCategory[];

			const optionGroupsMap = new Map(
				copyOfOptionGroupsValue.map(it => [it._id, it])
			);
			let groupedStockManagementGroups = {
				article: [],
				option: []
			};

			if (categories.length > 0) {
				categories = categories.map(cat => {
					cat.articles = cat.articles?.map(art => {
						if (art.groups && art.groups.length > 0) {
							art.groups = art.groups.map((group: any) => {
								if (optionGroupsMap.has(group)) {
									group = optionGroupsMap.get(group);
								}
								return group;
							});
						}
						return {...art, categoryName: cat.name.de} as Article;
					});
					return cat;
				});
				const a = this.categoryService.generateItems(categories, filters);

				groupedStockManagementGroups = a.reduce((acc, group) => {
					if (group) {
						const {type} = group;
						if (!acc[type]) {
							acc[type] = [];
						}
						acc[type].push(group);
					}
					return acc;
				}, {});
			}

			this.venueId = venue?._id ?? '';

			return groupedStockManagementGroups as {
				article: {
					parentName: string;
					connectedArticles: {de: string}[];
					parentDescription: string;
					article: Article;
					type: StockManagementType;
				}[];
				option: {
					parentName: string;
					connectedArticles: {de: string}[];
					parentDescription: string;
					article: Article;
					type: StockManagementType;
				}[];
			};
		}),

		tap(() => {
			this.animationState = 'end';
		}),
		takeUntil(this.onDestroy$)
	);
	constructor(
		private managerFacade: ManagerFacade,
		private categoryService: CategoryToArticleService,
		public dialog: MatDialog
	) {}

	ngOnInit() {
		this.articles$.pipe(takeUntil(this.onDestroy$)).subscribe(v => {});
	}
	ngOnDestroy(): void {
		this.onDestroy$.next();
	}
	sleep(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
	async changeItem(event: boolean, item, isOption = false) {
		console.log('item', item);
		if (this.venueId) {
			this.managerFacade.updateArticlesActive({
				articles: [item._id],
				venue: this.venueId,
				action: event ? 'visible ' : 'hide',
				isOption: isOption
			});
			await this.sleep(1000);
		}
	}
	async changeItemActive(event: boolean, item, isOption = false) {
		if (this.venueId) {
			this.managerFacade.updateArticlesActive({
				articles: [item._id],
				venue: this.venueId,
				action: event ? 'activate ' : 'deactivate',
				isOption: isOption
			});
			await this.sleep(1000);
		}
	}
	openDialog(
		item: {
			parentName: string;
			connectedArticles: {de: string}[];
			parentDescription: string;
			article: Article;
			type: StockManagementType;
		} // item
	): void {
		const dialogRef = this.dialog.open(ArticleWithGroupsModalComponent, {
			data: {...item}
		});
		dialogRef.afterClosed().subscribe(
			(result: {
				data: {
					article: Article;
					selected: boolean;
				}[];
				dataIsActive: {
					article: Article;
					isActive: boolean;
				}[];
			}) => {
				console.log('-------', result);
				const foundArticleToHide = result.data.filter(
					it => it.selected == false
				);
				const foundArticleToShow = result.data.filter(
					it => it.selected == true
				);
				const foundArticlesToActive = result.dataIsActive.filter(
					it => it.isActive == true
				);
				const foundArticlesToInActive = result.dataIsActive.filter(
					it => it.isActive == false
				);
				// if (foundArticleToHide.length > 0 && foundArticleToShow.length > 0) {
				this.managerFacade.updateListOfOptionArticles({
					active: {
						articles: foundArticleToShow.map(it => it.article._id),
						venue: this.venueId,
						action: 'visible',
						isOption: true
					},
					disabled: {
						articles: foundArticleToHide.map(it => it.article._id),
						venue: this.venueId,
						action: 'hide',
						isOption: true
					},
					isActive: {
						articles: foundArticlesToActive.map(it => it.article._id),
						venue: this.venueId,
						action: 'activate',
						isOption: true
					},
					inActive: {
						articles: foundArticlesToInActive.map(it => it.article._id),
						venue: this.venueId,
						action: 'deactivate',
						isOption: true
					}
				});
				return;
				// }
				// if (foundArticleToShow.length > 0) {
				// 	this.managerFacade.updateArticlesActive({
				// 		articles: foundArticleToShow.map(it => it.article._id),
				// 		venue: this.venueId,
				// 		action: 'visible',
				// 		isOption: true
				// 	});
				// 	return;
				// }
				// if (foundArticleToHide.length > 0) {
				// 	this.managerFacade.updateArticlesActive({
				// 		articles: foundArticleToHide.map(it => it.article._id),
				// 		venue: this.venueId,
				// 		action: 'hide',
				// 		isOption: true
				// 	});
				// 	return;
				// }
			}
		);
	}
}
