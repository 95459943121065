import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime, map} from 'rxjs';
import ArticleCategory from 'src/app/models/ArticleCategory';
import {ManagerFacade} from 'src/app/store/manager/+state/manager.facade';

export enum StatusFormFilter {
	ALL = '',
	ACTIVE = 'active',
	NOT_ACTIVE = 'not_active'
}
export enum TypeFormFilter {
	ALL = '',
	ARTICLE = 'article',
	OPTION = 'option'
}
@Component({
	selector: 'app-stock-manager-header-filters',
	templateUrl: './stock-manager-header-filters.component.html',
	styleUrls: ['./stock-manager-header-filters.component.scss']
})
export class StockManagerHeaderFiltersComponent implements OnInit, OnDestroy {
	public formFilters: FormGroup;

	public categories$ = this.managerFacade.categories$.pipe(
		map(it => {
			let copyCategories = JSON.parse(JSON.stringify(it)) as ArticleCategory[];
			let result: {text: string; value: string}[] = [];
			result.push({
				text: this.translate.instant(
					'filters.category.all_categories'
				) as string,
				value: ''
			});
			result.push(
				...copyCategories.map(it => {
					return {
						text: it.name.de,
						value: it._id
					};
				})
			);

			return result;
		})
	);
	public status = [
		{
			value: '',
			text: this.translate.instant('filters.status.all_status')
		},
		{
			value: StatusFormFilter.ACTIVE,
			text: this.translate.instant('filters.status.active')
		},
		{
			value: StatusFormFilter.NOT_ACTIVE,
			text: this.translate.instant('filters.status.not_active')
		}
	];
	public type = [
		{
			value: '',
			text: this.translate.instant('filters.type.all')
		},
		{
			value: TypeFormFilter.ARTICLE,
			text: this.translate.instant('filters.type.article')
		},
		{
			value: TypeFormFilter.OPTION,
			text: this.translate.instant('filters.type.options')
		}
	];

	constructor(
		private fb: FormBuilder,
		private managerFacade: ManagerFacade,
		private translate: TranslateService
	) {
		this.formFilters = this.fb.group({
			category: '',
			status: '',
			type: '',
			search: ''
		});
	}

	ngOnInit() {
		this.formFilters.valueChanges.pipe(debounceTime(400)).subscribe(result => {
			this.managerFacade.setStockManagerFilters(result);
		});
	}
	ngOnDestroy(): void {}
}
